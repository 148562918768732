import React, { Component } from 'react';
import Waypoint from 'react-waypoint';

class Reveal extends Component {
  constructor() {
    super();
    this.state = {
      revealed: false,
    };
  }
  handleReveal = () => {
    this.setState({ revealed: true });
  };
  render() {
    // console.log(this.props.children);
    return (
      <Waypoint onEnter={this.handleReveal} bottomOffset="50px">
        <div className={this.state.revealed ? 'revealed' : ''}>
          {React.cloneElement(this.props.children, {
            revealed: this.state.revealed,
            hasRevealer: true,
          })}
        </div>
      </Waypoint>
    );
  }
}

export default Reveal;
